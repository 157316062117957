import {
  DeclarationModel,
  DeclarationModelWithFiness,
} from "@/models/declarations"
import { API_URL } from "@/utils/config"
import fetcher from "@/utils/fetcher"
import { flatObject } from "@/utils/object"
import {
  factGoodsGroups,
  factPersonsGroups,
  reasons,
  jobsByOrders,
} from "@/utils/options"
import { getSecureToken } from "@/utils/secureTokenHelper"
import { formatISO } from "date-fns"

const DECLARATION_ENDPOINT = "declarations"

// Extract town and postal code in the field. For example : "Nantes (44000)".
function extractTownAndPostalCode(town) {
  // Regex magic ✨
  // There is 2 captured groups : 1/ everthing before a parenthesis, 2/ everything between the '(' and the ')'.
  const groups = town?.match(/([^(]+)\(([^)]+)\)/)

  if (groups?.[2]) {
    return { town: groups[1]?.trim(), postalCode: groups[2]?.trim() }
  }
  return { town }
}

const sendNotifToOrder = async (order, declarationData) => {
  const { data: mails } = await fetcher(`${API_URL}/mailorder`, {
    headers: {
      "Content-Type": "application/json",
    },
    method: "GET",
  })

  const mail = mails.find((a) => a.name === order)?.email
  if (!mail) return

  const { persons, properties } = declarationData
  const personLevel = persons ? `Personnes niveau ${persons}` : ""
  const propertyLevel = properties ? `Biens niveau ${properties}` : ""
  const atteintes = [personLevel, propertyLevel].filter((a) => !!a).join(" - ")

  const date = formatISO(new Date(), { representation: "date" })
  const text = `Une violence d’un professionnel de santé de votre Ordre a été inscrite sur la plateforme-signalement le ${date} sous le n°${declarationData?.numero}\nCordialement\nDGOS-ONVS\n(Courriel envoyé automatiquement, veuillez ne pas y répondre)`
  const subject = `Violence signalée sur l’ONVS ${
    !!persons || !!properties ? `(${atteintes})` : ""
  }`

  try {
    await fetcher(`${API_URL}/sendmail`, {
      body: JSON.stringify({ text, subject, email: mail }),
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
    })
  } catch (e) {
    console.error("Error send mail: ", e)
  }
}

// Note: this function returns a Promise, as expected, even await is not required by return syntax.
export const createDeclaration = async ({
  declaration,
  keys,
  email
}: {
  declaration: DeclarationModel
  keys: Array<any>
  email: string
}): Promise<string> => {
  const token = await getSecureToken()
  const declarationData = convertToData(declaration, keys, email)

  const ret = await fetcher(`${API_URL}/${DECLARATION_ENDPOINT}`, {
    body: JSON.stringify(declarationData),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${process.env.NEXT_PUBLIC_ONVS_API_TOKEN}`,
      "CSRF-TOKEN": token,
    },
    method: "POST",
  })

  const order = Object.keys(jobsByOrders).find(
    (key) => !!jobsByOrders[key].includes(declarationData?.job),
  )

  if (order) sendNotifToOrder(order, ret)

  return ret?.id
}

export function convertToData(declaration, keys, email?: string) {
  const data = flatObject(declaration?.steps, keys)
  const { town, postalCode } = extractTownAndPostalCode(data?.town?.label)

  // Reminder : the id is generated client side, to prevent multiple submits.
  data.id = declaration.id
  data.declarationType = declaration.declarationType
  if (data.declarationType === "ets") {
    data.email = email
  }
  
  if (data.declarationType === "ets") {
    data.town = ""
    data.postalCode = ""
    if (declaration.finesset) {
      data.finesset = declaration.finesset
    }
  } else {
    data.town = town
    data.postalCode = postalCode
  }
  data.hour = data.hour.label

  if (declaration?.steps?.job) {
    // job is outside the ordered steps by flow. Refactor the wizard forms to better manage job info transfer
    data.job = declaration.steps.job.job.label
  }

  // LOCATION ---------------------------------------------------------------

  if (data.declarationType === "ets") {
    data.location = {
      "Dans quel type d'établissement ?": data.otherLocationMain
        ? ["Autre", data.otherLocationMain]
        : data.locationMain?.label,

      "Dans quel secteur précisément ?": data.otherLocationSecondary
        ? ["Autre", data.otherLocationSecondary]
        : data.locationSecondary?.label,

      "Dans quel lieu précisément ?": data.otherLocationThird
        ? ["Autre", data.otherLocationThird]
        : data.locationThird.map(({ label }) => label),
    }

    delete data.locationMain
    delete data.locationSecondary

    delete data.otherLocationMain
    delete data.otherLocationSecondary

    delete data.locationThird
    delete data.otherLocationThird
    delete data.isOther
  } else {
    // Should be the liberal flow.
    data.location = {
      "Dans quel lieu précisément ?": data.otherLocation
        ? ["Autre", data.otherLocation]
        : data.location,
    }
    if (data.location["Dans quel lieu précisément ?"] === "Officine") {
      data.location["En situation de garde ?"] = data.locationOfficine
    }
    if (data.locationOfficine === "Oui") {
      data.location[
        "Filtrage réalisé par les forces de l'ordre lors de la garde ?"
      ] = data.locationOfficineFilter
    }
    if (data.location["Dans quel lieu précisément ?"] === "Officine") {
      data.location["Dispositif de surveillance ?"] = data.locationSurveillance
    }

    delete data.locationOfficine
    delete data.locationOfficineFilter
    delete data.locationSurveillance
    delete data.otherLocation
  }

  // FACTS  -----------------------------------------------------------------------------------

  data.factPersons = Object.keys(factPersonsGroups)
    .map((key) => ({
      key,
      label: factPersonsGroups[key].label,
      data: data[key],
    }))
    .filter((elt) => elt.data.length)
    .map((elt) =>
      elt.key !== "fpPhysicalViolences"
        ? elt
        : {
            ...elt,
            data: elt.data.map((option) =>
              option !== "Autre fait qualifié de crime"
                ? option
                : [
                    "Autre fait qualifié de crime",
                    data?.fpPhysicalViolencesPrecision,
                  ],
            ),
          },
    )
    .reduce((acc, current) => ({ ...acc, [current.label]: current.data }), {})

  data.factGoods = Object.keys(factGoodsGroups)
    .map((key) => ({
      key,
      label: factGoodsGroups[key].label,
      data: data[key],
    }))
    .filter((elt) => elt.data.length)
    .reduce((acc, current) => ({ ...acc, [current.label]: current.data }), {})

  delete data.fpSpokenViolences
  delete data.fpSexualViolences
  delete data.fpPsychologicalViolences
  delete data.fpPhysicalViolences
  delete data.fpPhysicalViolencesPrecision
  delete data.fpOthers
  delete data.fpNoRespects
  delete data.fpGroups
  delete data.fpDiscriminations
  delete data.fgStealWithoutBreakins
  delete data.fgStealWithBreakins
  delete data.fgStealAggravated
  delete data.fgOthers
  delete data.fgGroups
  delete data.fgDeteriorations
  delete data.factTypes

  // REASONS  -----------------------------------------------------------------------------------

  data.reasons = Object.keys(reasons)
    .map((key) => ({
      ...reasons[key],
      data: data[key],
    }))
    .filter((elt) => elt.data.length)
    .map((elt) => ({
      ...elt,
      data: elt.data.map((option) => {
        const [optionDefinition] = elt.options.filter(
          (aux) => aux.value === option,
        )

        return !optionDefinition?.precision
          ? option
          : [optionDefinition.value, data?.rOthersPrecision]
      }),
    }))
    .reduce((acc, current) => ({ ...acc, [current.label]: current.data }), {})

  if (!Object.keys(data.reasons).length) {
    data.reasons = {}
  }

  data.reasonNotApparent = Boolean(data.rNotApparent)

  delete data.rCausePatients
  delete data.rCauseProfessionals
  delete data.rDiscords
  delete data.rLifeRules
  delete data.rFalsifications
  delete data.rDeficientCommunications
  delete data.rOthers
  delete data.rOthersPrecision
  delete data.rNotApparent

  // DECLARANT_CONTACT_AGREEMENT ---------------------------------------------------------------
  data.declarantContactAgreement =
    data.declarantContactAgreement === "true"
      ? true
      : data.declarantContactAgreement === "false"
      ? false
      : null

  // PURSUIT  ---------------------------------------------------------------

  if (data.pursuit === "Non") {
    delete data.pursuit // We don't store the pursuit anymore when no is choosen.
  } else {
    data.pursuit = {
      type:
        data.pursuit === "Autre"
          ? ["Autre", data.pursuitPrecision]
          : data.pursuit,
      ...(data.pursuit === "Plainte" && { pursuitBy: data.pursuitBy }),
    }
  }

  delete data.pursuitBy

  // THIRD_PARTY  ---------------------------------------------------------------

  if (data.thirdPartyIsPresent === "Non") {
    delete data.thirdParty
  } else {
    data.thirdParty = data.thirdParty.map((elt) =>
      elt === "Autre" ? ["Autre", data.thirdPartyPrecision] : elt,
    )
  }

  delete data.thirdPartyIsPresent
  delete data.thirdPartyPrecision

  // VICTIMS & AUTHORS ---------------------------------------------------------------

  if (data.victims?.length) {
    const victims = data.victims.map((victim) => {
      return {
        ...victim,
        type: victim.type.label,
        gender: victim.gender?.label,
        age: victim.age?.label,
        ...(victim.healthJob && { healthJob: victim.healthJob.label }),
      }
    })

    data.victims = victims
  }

  if (data.authors?.length) {
    const authors = data.authors.map((author) => {
      // We ignore discernmentTroublesIsPresent, since it can be deduce if the discernmentTroubles is an empty array.
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { discernmentTroublesIsPresent, ...restAuthors } = author

      return {
        ...restAuthors,
        type: author.type.label,
        gender: author.gender?.label,
        age: author.age?.label,
        ...(author.healthJob && { healthJob: author.healthJob.label }),
      }
    })

    data.authors = authors
  }
  return data
}

export const deleteDeclaration = async (
  id: string,
): Promise<DeclarationModel> => {
  return fetcher(`${API_URL}/${DECLARATION_ENDPOINT}/${id}`, {
    headers: { "Content-Type": "application/json" },
    method: "PATCH",
  })
}

export const findDeclaration = async (
  id: string,
): Promise<DeclarationModelWithFiness> => {
  return fetcher(`${API_URL}/${DECLARATION_ENDPOINT}/${id}`, {
    headers: { "Content-Type": "application/json" },
    method: "GET",
  })
}
