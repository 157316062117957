import { useState, FormEventHandler, useRef } from 'react';
import { CaptchEtat } from 'captchetat-react';


type ResultType = null | true | false;

interface CaptchetatProps {
    styleName: string,
    onValidate: () => void,
}
type PostData = {
    captchaCode: string,
    captchaId: string
}

type CaptchetatType = {
    reloadImage: () => void;
  };

const Captchetat = ({ styleName, onValidate }: CaptchetatProps): JSX.Element => {
    const [result, setResult] = useState<ResultType>(null);
    const [values, setValues] = useState<PostData>({ captchaCode: '', captchaId: '' });

    const urlBackend = '/api/captcha-endpoint';
    const captchetatRef = useRef<CaptchetatType | null>(null);

    const handleChange = (event) => {
        setValues({ ...values, [event.target.name]: event.target.value });
    }
    // Fonction permettant de mettre à jour l’uuid du captcha à envoyer pour la validation
    const onCaptchaChange = (captcha) => {
        setValues({ ...values, captchaId: captcha.uuid })
    }

    const handleOnSubmit: FormEventHandler = async (e) => {
        if (result !== null) setResult(null);

        e.preventDefault();

        const postData = {
            code: values.captchaCode.toUpperCase(),
            uuid: values.captchaId,
        }

        const apiResult = await fetch("/api/submit", {
            method: "POST",
            body: JSON.stringify(postData),
            headers: { "Content-Type": "application/json; charset=utf-8" },
        }).then((r) => {
            try {
                return r.json();
            } catch (e) {
                console.log(r.statusText);
                console.log(r.text());
                console.log(e);
            }
        });

        if (apiResult && apiResult.success === false) {
            setResult(false);
            if(captchetatRef.current !== null) {
            captchetatRef.current?.reloadImage();
        }
        } else {
            setResult(true);
            onValidate();
        }
        e.preventDefault();
    }

    return (
        <section id="main-content">
            <form id="captchaForm" method="POST" onSubmit={handleOnSubmit} className="flex flex-col items-center">
                <p className='text-sm mb-2'>Veuillez compléter le Captcha pour accéder au formulaire</p>
                <CaptchEtat urlBackend={urlBackend} captchaStyleName={styleName}
                    onChange={onCaptchaChange} className="captchetat" ref={captchetatRef} />
                <input name="captchaCode" type="text" onChange={handleChange} placeholder="Saisir le captcha"
                    className="captchetat-response fr-input fr-m-4w" style={{ width: "200px" }} />
                <div className="text-center mb-2">
                    <button type="submit" className="fr-btn">Valider</button>
                </div>
                {result === false && <p className='text-red-500'>Code invalide, veuillez recommencer</p>}
                {result === true && <p>Code valide, redirection en cours</p>}
            </form>
        </section>
    );
}

export default Captchetat;
