import Link from "next/link"
import React from "react"
import { saveAs } from "file-saver"

import "@gouvfr/dsfr/dist/component/footer/footer.min.css"

const Footer = () => {
  const links = [
    { url: "/plan-site", label: "Plan du site" },
    { url: "/accessibilite", label: "Accessibilité : partiellement conforme" },
    { url: "/apropos", label: "Qui sommes-nous ?" },
    { url: "/mentions", label: "Mentions légales" },
    {
      url: [
        "/pdf/ONVS FAQ (2023-09).pdf",
        "/pdf/ONVS Ets Indications essentielles (2023-09).pdf",
        "/pdf/ONVS Libéraux (ville) indications essentielles (2023-09).pdf",
        "/pdf/ONVS Échelle de gravité (2023-09).pdf",
      ],
      label: "FAQ - Indication essentielles - Échelle de gravité",
    },
    {
      url: "/politique-confidentialite",
      label: "Politique de confidentialité",
    },
    { url: "/cgu", label: "Conditions générales d'utilisation" },
    { label: "v3.6.0" },
  ]

  const savePdfs = (urls: string[]) => {
    urls.forEach(async (url) => {
      const response = await fetch(url)
      const pdfBlob = await response.blob()
      saveAs(pdfBlob, `${url.split("/").pop()}`)
    })
  }

  const showElement = (elem: { url?: string | string[]; label: string }) => {
    if (typeof elem.url === "string") {
      return (
        <Link
          href={elem.url}
          className="fr-footer__bottom-link"
          download={true}
        >
          {elem.label}
        </Link>
      )
    } else if (elem.url) {
      return (
        <Link
          className="fr-footer__bottom-link"
          href={""}
          role="link"
          tabIndex={0}
          onClick={() =>
            elem.url && Array.isArray(elem.url) && savePdfs(elem.url)
          }
          onKeyDown={() =>
            elem.url && Array.isArray(elem.url) && savePdfs(elem.url)
          }
        >
          {elem.label}
        </Link>
      )
    } else {
      return <span className="fr-footer__bottom-link">{elem.label}</span>
    }
  }
  return (
    <footer className="fr-footer" role="contentinfo" id="footer">
      <div className="fr-container">
        <div className="fr-footer__body">
          <div className="fr-footer__brand fr-enlarge-link">
            <Link
              href="/"
              title="Retour à l’accueil du site - ONVS (Ministère de la santé et de la prévention)"
            >
              <p className="fr-logo">
                Ministère
                <br />
                de la santé
                <br />
                et de la prévention
              </p>
            </Link>
          </div>
          <div className="fr-footer__content">
            <ul className="fr-footer__content-list">
              <li className="fr-footer__content-item">
                <a
                  className="fr-footer__content-link"
                  target="_blank"
                  href="https://legifrance.gouv.fr"
                  rel="noreferrer"
                >
                  legifrance.gouv.fr
                </a>
              </li>
              <li className="fr-footer__content-item">
                <a
                  className="fr-footer__content-link"
                  target="_blank"
                  href="https://gouvernement.fr"
                  rel="noreferrer"
                >
                  gouvernement.fr
                </a>
              </li>
              <li className="fr-footer__content-item">
                <a
                  className="fr-footer__content-link"
                  target="_blank"
                  href="https://service-public.fr"
                  rel="noreferrer"
                >
                  service-public.fr
                </a>
              </li>
              <li className="fr-footer__content-item">
                <a
                  className="fr-footer__content-link"
                  target="_blank"
                  href="https://data.gouv.fr"
                  rel="noreferrer"
                >
                  data.gouv.fr
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="fr-footer__bottom">
          <ul className="fr-footer__bottom-list">
            {links.map((elem, id) => (
              <li className="fr-footer__bottom-item" key={id}>
                {showElement(elem)}
              </li>
            ))}
          </ul>
          <div className="fr-footer__bottom-copy">
            <p>
              Sauf mention contraire, tous les contenus de ce site sont proposés sous{" "}
              <a
                href="https://github.com/etalab/licence-ouverte/blob/master/LO.md"
                target="_blank"
                rel="noreferrer"
              >
                licence etalab-2.0
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
